
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class AppImage extends Vue {
  @Prop({
    required: true,
    type: Array,
    default: '',
  })
  imageSrc!: string[]

  @Prop({
    required: false,
    type: String,
    default: '',
  })
  previewSrc!: string

  @Prop({
    required: false,
    type: String,
    default: '',
  })
  fallbackImage!: string

  imageLoaded = false

  get srcsetStr () {
    if (!this.imageLoaded) {
      return this.previewSrc ? this.previewSrc : this.imageSrc[0]
    }

    if (this.imageSrc.length === 1) {
      return this.imageSrc[0]
    }

    return this.imageSrc.map((image, index) => `${image} ${index + 1}x`).join(', ')
  }
}
